import ALL from "./ALL";

const allBacks = ["MB", "TB", "E", "M", "T", "C", "L", "U", "S", "G", "W", "X", "P", "B"];

const allSeries = [
  "X",
  "MVMT",
  "MTCL",
  "MTHD",
  "AM",
  "BT",
  "EE",
  "EB",
  "Z",
  "GG",
  "UU",
  "KX",
  "WW",
  "IP",
  "2A",
  "RX",
  "RT",
  "TX",
  "CX",
  "GX",
  "AJ",
  "TXG",
  "Scepter",
];

const allSeriesNotEmbark = allSeries.filter((code) => code !== "EB");

export default [
  {
    code: "P28540",
    name: "Black Vinyl",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["06", "C", "ISO8", "ISO7", "ISO6", "ISO5", "ISO4", "ISO3", "ISO8C", "ISO7C"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "P28605",
    name: "Charcoal Vinyl",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["06", "C", "ISO8", "ISO7", "ISO6", "ISO5", "ISO4", "ISO3", "ISO8C", "ISO7C"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: ALL,
  },
  {
    code: "P28542",
    name: "Royal Blue Vinyl",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["06", "C", "ISO8", "ISO7", "ISO6", "ISO5", "ISO4", "ISO3", "ISO8C", "ISO7C"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "NH333",
    name: "Black Polyester",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["06", "C"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "NH369",
    name: "Navy Polyester",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["06", "C"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "XBLK",
    name: "Black Vacuum Formed Vinyl",
    description: "",
    seat: ["VF:R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  // {
  //   code: "XBLU",
  //   name: "Blue Vacuum Formed Vinyl",
  //   description: "",
  //   seat: ["VF:F", "VF:H", "VF:R"],
  //   back: allBacks.filter((code) => code !== "B"),
  //   base: ALL,
  //   height: ALL,
  //   casters: ALL,
  //   control: ALL,
  //   footring: ALL,
  //   arm: ALL,
  //   performance: ALL,

  //   memoryFoam: ALL,
  //   vinylUnderwrap: ALL,
  //   series: allSeriesNotEmbark,
  // },

  {
    code: "MBLU",
    name: "Blue Microcon",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["ISO8K", "ISO7K", "ISO6K", "ISO5K", "ISO4K", "ISO3K", "K"],

    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "MBLK",
    name: "Black Microcon",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["ISO8K", "ISO7K", "ISO6K", "ISO5K", "ISO4K", "ISO3K", "K"],
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "XTBLK",
    name: "Black BioGrip",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["ISO8K", "ISO7K", "ISO6K", "ISO5K", "ISO4K", "ISO3K", "K"],
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "GEBONY",
    name: "Black Grounded",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["K"],
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "GNAVY",
    name: "Navy Grounded",
    description: "",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ["K"],
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "BLACK",
    name: "Black",
    description: "",
    seat: ["G", "K", "U", "C", "T", "TX", "L"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "MAPLE",
    name: "Maple",
    description: "",
    seat: ["W"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
  {
    code: "BLUE",
    name: "Blue",
    description: "",
    seat: ["G", "K"],
    back: allBacks.filter((code) => code !== "B"),
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    memoryFoam: ALL,
    vinylUnderwrap: ALL,
    series: allSeriesNotEmbark,
  },
];

import _ from "lodash";
import { fromCodeToMvmtCode, getMvmtSeries } from "./manufactureCode";

// figure out the color code from the upholstery
const colors = {
  P28540: "BLACKVINYL",
  NH333: "BLACKPOLYESTER",
  XBLK: "BLACKVINYL",
  MBLK: "BLACKVINYL",
  XTBLK: "BLACKVINYL",
  GEBONY: "BLACKPOLYESTER",
  P28605: "CHARCOAL",
  P28542: "ROYAL",
  NH369: "NAVY",
  MBLU: "ROYAL",
  GNAVY: "NAVY",
  BLACK: "BLACK",
  MAPLE: "MAPLE",
  BLUE: "BLUE",
};

const getColorCode = (upholstery) => {
  if (!upholstery) return "";

  if (colors[upholstery]) return `-${colors[upholstery]}`;
  return "";
};

const mvmtImages = (selected) => {
  const mvmtCodes = fromCodeToMvmtCode(selected);

  const { seat, back, base, height, casters, control, footring, arm, performance, upholstery } =
    mvmtCodes;

  const getUpper = () => {
    const partA = `${seat}-${control}-${arm}-${back}`;

    let partB;
    const series = getMvmtSeries(mvmtCodes);

    // Pro series logic only
    if (series === "MP") {
      // Add BOOT if needed
      partB =
        ["ISO3", "ISO3K", "ISO5", "ISO5K", "WD", "WDK"].indexOf(performance) !== -1 ? "BOOT" : "";
    } else {
      // Tech series logic only
      // Ask for chrome top half unless black metal parts
      partB = "T";
      if (["06", "ISO8", "ISO7"].indexOf(performance) !== -1) partB = "06";
      // Add PAN if needed
      if (["ISO3", "ISO3K"].indexOf(performance) !== -1) partB += "-PAN";
    }

    return `${partA}${partB ? `-${partB}` : ""}${getColorCode(upholstery)}`;
  };

  const getLower = () => {
    // Remap ESD caster to RC, change added after images were created
    const finalCasters = casters === "RK" ? "RC" : casters;

    const partA = `${base}-${finalCasters}-${footring}-${height}`;

    let partB = "T";
    if (["K", "ISO8K", "ISO7K", "ISO6K", "ISO5K", "ISO3K", "WDK"].indexOf(performance) !== -1)
      partB = "ESD";
    if (["06", "ISO8", "ISO7"].indexOf(performance) !== -1) partB = "06";

    return `${partA}-${partB}`;
  };

  return {
    upper: getUpper(),
    lower: getLower(),
  };
};

const foundationImages = (selected) => {
  const {
    seat,
    back,
    base,
    height,
    casters: initialCasters,
    control,
    footring,
    arm,
    performance,
    upholstery,
  } = selected;

  // Convert GL to HG (images were rendered as HG before G glides were introduced)
  let casters = initialCasters === "GL" ? "HG" : initialCasters;

  // Convert CRC to RC, (images rendered before change)
  casters = casters === "CRC" ? "RC" : casters;

  const isAJStool = ["A18", "A24", "A30"].indexOf(base) !== -1;

  const invalidUpper = !seat || !back || !control || !arm || !performance || isAJStool;
  const invalidLower = !base || !height || !casters || !footring || !performance;

  const esd = ["K", "ISO6K", "ISO5K", "ISO4K", "ISO3K", "ISO7K", "ISO8K"];
  const chrome = ["C", "K", "ISO6", "ISO6K", "ISO7K", "ISO8K", "ISO8C", "ISO7C"];
  const chromePlusArms = ["ISO5", "ISO4", "ISO3", "ISO5K", "ISO4K", "ISO3K"];
  const panPerformance = ["ISO4", "ISO3", "ISO4K", "ISO3K"];
  const panSeat = ["A", "E", "B"];

  let performanceUpper = "06";
  let performanceLower = "06";
  if (chrome.indexOf(performance) !== -1) {
    performanceLower = "C";
    performanceUpper = "C";
  }
  if (chromePlusArms.indexOf(performance) !== -1) {
    performanceLower = "C";
    performanceUpper = arm === "XA" ? "C" : "CC";
  }

  if (panPerformance.indexOf(performance) !== -1 && panSeat.indexOf(seat) !== -1) {
    performanceUpper = "PAN";
  }

  // Special rule for showing chrome back bar for G and U seats with K package
  if (["G", "U"].indexOf(seat) !== -1 && performance === "K") performanceUpper = "C";

  if (esd.indexOf(performance) !== -1) performanceLower = "ESD";

  const upper = invalidUpper
    ? null
    : `${seat}-${control}-${arm}-${back}-${performanceUpper}${getColorCode(upholstery)}`;
  const lower = invalidLower
    ? null
    : `${base}-${casters}-${footring}-${height}-${performanceLower}`;

  return {
    upper: upper ? upper.replace(/:/g, "_") : null,
    lower: lower ? lower.replace(/:/g, "_") : null,
  };
};

const getImageNames = (selected) => {
  const { seat } = selected;

  const isMVMT = _.startsWith(seat, "1");

  if (isMVMT) return { ...mvmtImages(selected), type: "mvmt" };
  return { ...foundationImages(selected), type: "foundation" };
};

export default getImageNames;

import ALL from "./ALL";

export default [
  {
    code: "06",
    name: "06 - Black Metal Parts",
    description: `Metal parts coated in black powder coated finish for standard working environments.`,
    seat: ALL,
    back: ALL,
    base: ["A", "W", "R", "S", "TUH", "G", "A18", "A24", "A30", "P"],
    height: ALL,
    casters: ["RC", "HG", "IPR", "GL"],
    control: [
      "PM",
      "T",
      "FFAC",
      "E3",
      "EXE",
      "T:SLD",
      "FFAC:SLD",
      "EXE:SLD",
      "S",
      "Z",
      "P",
      "X",
      "L",
      "IP",
    ],
    footring: ALL,
    arm: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "XBLK", "BLACK", "BLUE", "MAPLE"],
    market: ["education", "industrial", "laboratory", "healthcare", "office"],
  },
  {
    code: "C",
    name: "C - Chrome Plated Metal Parts",
    formerly: "T",
    description: `Appropriate metal parts are chrome-plated to stand up to the rigors of technical work areas.`,
    seat: ALL,
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "P", "B"],
    base: ["A", "W", "R", "S", "TUH", "C", "A18", "A24", "A30", "G"],
    height: ALL,
    casters: ["RC", "HG", "GL", "CRC"],
    control: [
      "PM",
      "T",
      "FFAC",
      "E3",
      "EXE",
      "T:SLD",
      "FFAC:SLD",
      "EXE:SLD",
      "S",
      "Z",
      "P",
      "FA",
      "X",
      "L",
    ],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "NH333", "NH369", "XBLK", "BLACK", "BLUE", "MAPLE"],
    market: ["education", "industrial", "laboratory", "healthcare", "office"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 5,
      },
      {
        component: "series",
        code: ["MTCL"],
        price: 58,
      },
      {
        component: "series",
        code: ["MTHD"],
        price: 130,
      },
      {
        component: "series",
        code: ["AJ"],
        price: 37,
      },
      {
        component: "seat",
        code: ["L"],
        price: 87,
      },
      {
        component: "seat",
        code: ["TX"],
        price: 56,
      },
      {
        matchAll: [
          {
            component: "base",
            code: ["A", "R", "W"],
          },
          {
            component: "back",
            code: ["X"],
          },
        ],
        price: 53,
      },
      {
        matchAll: [
          {
            component: "base",
            code: ["A", "R", "W"],
          },
          {
            component: "back",
            code: ALL,
          },
        ],
        price: 58,
      },
      {
        matchAll: [
          {
            component: "base",
            code: ["S"],
          },
          {
            component: "back",
            code: ["X"],
          },
        ],
        price: 103,
      },
      {
        matchAll: [
          {
            component: "base",
            code: ["S"],
          },
          {
            component: "back",
            code: ALL,
          },
        ],
        price: 122,
      },
    ],
  },
  {
    code: "K",
    name: "K - ESD",
    description: `All chair components treated to assure grounding through the chair to ESD flooring or mat. This
      includes the use of conductive casters or glides, metal bases, ground control lead to assure thorough static drain
      if casters become insulated by dirt or dust, ESD upholstery materials, and metal-to-metal contact throughout the
      chair for all metal parts.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "T", "R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "X", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "S"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["GEBONY", "GNAVY", "BLACK", "BLUE", "MBLK", "MBLU", "XTBLK"],
    market: ["staticControl"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 376,
      },
      {
        component: "series",
        code: ["MTCL", "MTHD"],
        price: 286,
      },
      {
        component: "series",
        code: ALL,
        price: 287,
      },
    ],
  },

  {
    code: "ISO8",
    name: "ISO Class 8 (Black)",
    formerly: "8",
    description: `All chair parts are selected to assure ISO class 8 clean room compatibility.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "K"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE", "P"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: 26,
  },

  {
    code: "ISO8C",
    name: "ISO Class 8 (Chrome)",
    description: `All chair parts are selected to assure ISO class 8 clean room compatibility. Uses chrome parts.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "K"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE", "P"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: 26,
  },
  {
    code: "ISO8K",
    description: `All chair parts are selected to assure ISO class 8 clean room compatibility, plus ESD treatment as
      described in performance package "K".`,
    name: "ISO Class 8 / ESD",
    formerly: "8K",
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "BLACK", "BLUE", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 401,
      },
      {
        component: "series",
        code: ["MTCL", "MTHD"],
        price: 311,
      },
      {
        component: "series",
        code: ALL,
        price: 311,
      },
    ],
  },
  {
    code: "ISO7",
    description: `All chair parts are selected to assure ISO class 7 clean room compatibility, including vinyl
      underwrap of seat bottom.`,
    name: "ISO Class 7 (Black)",
    formerly: "7",
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "K"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE", "P"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: 51,
  },
  {
    code: "ISO7C",
    description: `All chair parts are selected to assure ISO class 7 clean room compatibility, including vinyl
      underwrap of seat bottom. Uses chrome parts.`,
    name: "ISO Class 7 (Chrome)",
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "K"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE", "P"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: 51,
  },
  {
    code: "ISO7K",
    description: `All chair parts are selected to assure ISO class 7 clean room compatibility, including vinyl
      underwrap of seat bottom, plus ESD treatment as described in performance package "K".`,
    name: "ISO Class 7 / ESD",
    formerly: "7K",
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "T:SLD", "FFAC:SLD", "EXE:SLD", "EXE"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "BLACK", "BLUE", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 427,
      },
      {
        component: "series",
        code: ["MTCL", "MTHD"],
        price: 337,
      },
      {
        component: "series",
        code: ALL,
        price: 337,
      },
    ],
  },
  {
    code: "ISO6",
    name: "ISO Class 6",
    formerly: "1000",
    description: `Also known as "1000". All chair parts are selected to assure ISO class 6 clean room compatibility, including vinyl
      underwrap of seat bottom with filter media.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "VF:R", "C", "T"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "S"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 72,
      },
      {
        component: "series",
        code: ["MTCL", "MTHD"],
        price: 122,
      },
      {
        component: "series",
        code: ["AJ"],
        price: 72,
      },
      {
        component: "series",
        code: ALL,
        price: 122,
      },
    ],
  },
  {
    code: "ISO6K",
    name: "ISO Class 6 / ESD",
    formerly: "1000-K",
    description: `Also known as "1000-K". All chair parts are selected to assure ISO class 6 clean room compatibility, including vinyl
      underwrap of seat bottom with filter media, plus ESD treatment as described in performance package "K".`,
    seat: ["1", "1F", "1B", "A", "B", "E", "U", "G", "T"],
    back: ["MB", "TB", "E", "M", "T", "U", "G", "X", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC", "E3", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "S"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "BLACK", "BLUE", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: [
      {
        component: "series",
        code: ["MVMT"],
        price: 447,
      },
      {
        component: "series",
        code: ["MTCL", "MTHD"],
        price: 362,
      },
      {
        component: "series",
        code: ["TX"],
        price: 225,
      },
      {
        component: "series",
        code: ALL,
        price: 362,
      },
    ],
  },
  {
    code: "ISO5",
    name: "ISO Class 5",
    formerly: "VUV",
    description: `Also known as "VUV". All chair parts are selected to assure ISO class 5 clean room compatibility.`,
    seat: ["1F", "1B", "A", "B", "E", "K", "T"],
    back: ["MB", "TB", "E", "M", "T", "X", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC", "P", "S"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK", "BLUE"],
    market: ["cleanRoom"],
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 255,
      },
      {
        component: "series",
        code: ["AJ"],
        price: 97,
      },
      {
        component: "series",
        code: ["GG"],
        price: 163,
      },
      {
        component: "series",
        code: ["TX", "KX"],
        price: 163,
      },
      {
        component: "series",
        code: ALL,
        price: 255,
      },
    ],
  },
  {
    code: "ISO5K",
    name: "ISO Class 5 / ESD",
    formerly: "VUV-K",
    description: `Also known as "VUV-K". All chair parts are selected to assure ISO class 5 clean room compatibility, plus ESD treatment as
      described in performanc package "K".`,
    seat: ["1F", "1B", "A", "B", "E", "T"],
    back: ["MB", "TB", "E", "M", "T", "X", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC", "S"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "BLACK", "BLUE", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 495,
      },
      {
        component: "series",
        code: ["TX", "KX"],
        price: 265,
      },
      {
        component: "series",
        code: ALL,
        price: 495,
      },
    ],
  },
  {
    code: "ISO4",
    name: "ISO Class 4",
    formerly: "C10",
    description: `Also known as "C10". Appropriate metal parts are chrome-plated. All chair parts are selected to assure ISO class 4 clean room compatibility.`,
    seat: ["A", "B", "E", "TX", "G", "T"],
    back: ["E", "M", "T", "X", "G", "B"],
    base: ["A", "S", "W", "A18", "A24", "A30", "G"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["T", "S", "X"],
    footring: ALL,
    arm: ["PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK", "BLACK"],
    market: ["cleanRoom"],
    price: [
      {
        component: "series",
        code: ["AJ"],
        price: 121,
      },
      {
        component: "series",
        code: ["TXG", "TX", "GG", "KX"],
        price: 184,
      },
      {
        component: "series",
        code: ALL,
        price: 357,
      },
    ],
  },
  {
    code: "ISO4K",
    name: "ISO Class 4 / ESD",
    formerly: "C10-K",
    description: `Also known as "C10-K". Appropriate metal parts are chrome-plated. All chair parts are selected to assure ISO class 4 clean room compatibility, plus ESD treatment as described in performance package "K”.`,
    seat: ["A", "B", "E", "T", "G"],
    back: ["E", "M", "T", "X", "G", "B"],
    base: ["A", "S", "W"],
    height: ALL,
    casters: ["HG", "RK", "GL"],
    control: ["T", "S"],
    footring: ALL,
    arm: ["PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: [
      {
        component: "series",
        code: ["TX"],
        price: 283,
      },
      {
        component: "series",
        code: ALL,
        price: 597,
      },
    ],
  },
  {
    code: "ISO3",
    name: "ISO Class 3",
    formerly: "C1",
    description: `Also known as "C1". Appropriate metal parts are chrome-plated. All chair parts are selected to assure ISO class 3 clean room compatibility.`,
    seat: ["1F", "A", "B", "E", "VF:F", "VF:H"],
    back: ["MB", "TB", "E", "M", "T", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "GL"],
    control: ["PM", "T", "FFAC"],
    footring: ALL,
    arm: ["3D", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["P28540", "P28605", "P28542", "XBLK"],
    market: ["cleanRoom"],
    price: 372,
  },
  {
    code: "ISO3K",
    name: "ISO Class 3 / ESD",
    formerly: "C1-K",
    description: `Also known as "C1-K". Appropriate metal parts are chrome-plated. All chair parts are selected to assure ISO class 3 clean room compatibility, plus ESD treatment as described in performance package "K”.`,
    seat: ["1F", "A", "B", "E"],
    back: ["MB", "TB", "E", "M", "T", "B"],
    base: ["A", "W", "S", "TUH"],
    height: ALL,
    casters: ["RK", "HG", "GL"],
    control: ["PM", "T", "FFAC"],
    footring: ALL,
    arm: ["3D", "PSAS", "XA"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    upholstery: ["MBLU", "MBLK", "XTBLK"],
    market: ["cleanRoomStaticControl"],
    price: 612,
  },
];

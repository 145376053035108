import React from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import { Helmet } from "react-helmet";
import { PageContainer, Row, Column } from "../Grid";
import { H1, H2, DescriptionText } from "../TextStyles";
import { LargeButton } from "../Button";
import Breadcrumb from "../Breadcrumb";

import FoundationImg from "./bft-foundation-group-shot@2x.png";
import MVMTImg from "./bft-mvmt-group-shot@2x.png";
import styled from "styled-components";

const ImgBox = styled.div`
  height: 325px;
`;

const Style = () => {
  const location = useLocation();
  const { market } = location.state || {};
  if (!market) {
    return <Redirect to="/market" />;
  }
  return (
    <PageContainer>
      <Helmet>
        <title>Choose Your Style</title>
      </Helmet>
      <Breadcrumb />
      <H1>Choose Your Style</H1>
      <Flex flexWrap="wrap">
        <Box width={1} mb="1rem">
          If you’re not sure just where to begin, choose MVMT or Foundation, then answer a few
          simple questions below and we’ll suggest an ideal configuration. Your perfect custom chair
          is just a few clicks away!
        </Box>
        <Row flex="1" alignItems="start" flexWrap="wrap">
          <Column width={[1, 1, 2 / 5]}>
            <H2>MVMT</H2>
            <ImgBox>
              <img src={MVMTImg} alt="MVMT Style" />
            </ImgBox>
            <DescriptionText my="1rem">
              MVMT Ergonomic Seating represents a revolutionary advancement for today’s clean room,
              static control, healthcare, laboratory, industrial, and standing-desk applications.
            </DescriptionText>
            <Link to={{ pathname: "/series", state: { ...location.state, style: "MVMT" } }}>
              <LargeButton>Select MVMT</LargeButton>
            </Link>
          </Column>
          <Flex width={[1, 1, 1 / 5]} justifyContent="center" alignSelf="center">
            <H2>- Or -</H2>
          </Flex>
          <Column width={[1, 1, 2 / 5]}>
            <H2>Foundation</H2>
            <ImgBox>
              <img src={FoundationImg} alt="Foundation Style" />
            </ImgBox>
            <DescriptionText my="1rem">
              Our Foundation line consists of reliable ergonomic chairs and stools for all the
              markets we serve, with models ranging from task chairs to specialized seating for
              critical performance applications.
            </DescriptionText>
            <Link
              to={{ pathname: "/upholstery", state: { ...location.state, style: "foundation" } }}
            >
              <LargeButton>Select Foundation</LargeButton>
            </Link>
          </Column>
        </Row>
      </Flex>
    </PageContainer>
  );
};

export default Style;
